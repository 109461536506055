.post-content {
    p, ul, ol { @apply my-3 }
    h1, h2 { @apply mt-6 mb-4 }
    h3, h4 { @apply mt-6 mb-2 }
    h1 { @apply text-4xl }
    h2 { @apply text-2xl }
    h3 { @apply text-xl }
    h4 { @apply text-lg }
    ul, ol { @apply ml-6 list-outside }
    ul { @apply list-disc }
    ol { @apply list-decimal }
    li { @apply pl-1 }
    pre { @apply overflow-x-scroll bg-gray-300 rounded px-2 py-4 }
}

.post-content h2::before {
    content: "/ ";
    font-size: 1.2em;
    @apply text-green-600 font-bold
}

.post-content h3::before {
    content: "\\ ";
    font-size: 1.2em;
    @apply text-green-600 font-bold
}

.post-content h4::before {
    content: "/ ";
    font-size: 1.2em;
    @apply text-green-600 font-bold
}

.post-content .imgleft {
    max-width: 90%;
    @apply my-4 mx-auto
}

.post-content .imgright {
    max-width: 90%;
    @apply my-4 mx-auto
}

.post-content .imgcenter {
    max-width: 90%;
    @apply my-4 mx-auto;
    img {
        @apply mx-auto
    }
}

.post-content figcaption {
    @apply text-black-300 pt-1
}

.post-content blockquote {
    @apply block m-4 w-full bg-gray-300 py-2 px-3;
    &.left {
        float: left;
        @apply border-l-8 border-green-400 rounded-r ml-0
    }
    &.right {
        float: right;
        @apply border-r-8 border-green-400 rounded-l mr-0
    }
}

@screen sm {
    .post-content .imgright {
        float: right;
        max-width: 50%;
        @apply ml-4
    }

    .post-content .imgleft {
        float: left;
        max-width: 50%;
        @apply mr-4
    }

    .post-content blockquote {
        @apply w-5/12
    }
}
