.icard_toggle {
    padding: 0 2px;
    svg {
        display: inline;
        width: auto;
    }
}

.icard_toggle:hover {
    @apply underline;
    cursor: pointer;
}

.icard_content {
    display: none;
    @apply bg-gray-400 px-4 py-3 m-2 rounded;
}
