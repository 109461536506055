@import "tailwindcss/base";
@import "tailwindcss/components";

@import "infocard";
@import "waves";
@import "post-content";

@import "tailwindcss/utilities";

body {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 17px;
}

a {
    @apply underline
}

a:hover {
    @apply opacity-75
}

@media screen and (min-width: 420px) {
    .home_header-text {
        font-size: 3.8rem;
    }
}

.preview-card h1 {
    @apply text-lg my-0
}

.preview-card p {
    @apply my-0
}

.btn-sm {
    @apply inline-block px-2 pb-1 bg-black-400 rounded text-green-400 no-underline text-sm font-semibold;
    padding-top: 0.1rem;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #514F50;
    opacity: 0.9; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #514F50;
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: #514F50;
}
