.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.waves__parallax >use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}
.waves__parallax >use:nth-child(1) {
    animation-delay: -6s;
    animation-duration: 13s;
}
.waves__parallax >use:nth-child(2) {
    animation-delay: -4s;
    animation-duration: 15s;
}
.waves__parallax >use:nth-child(3) {
    animation-delay: -8s;
    animation-duration: 17s;
}
.waves__parallax >use:nth-child(4) {
    animation-delay: -2s;
    animation-duration: 20s;
}
@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
    .waves {
        height: 50px;
        min-height: 50px;
    }
}
